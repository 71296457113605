import has from 'lodash.has';
import includes from 'lodash.includes';
import isElement from 'lodash.iselement';
import isFinite from 'lodash.isfinite';
import isString from 'lodash.isstring';
import pick from 'lodash.pick';
import PostMessageIO from '@atlassian/trello-post-message-io';
import Promise from 'bluebird';
import xtend from 'xtend';

import { namedColorStringToHex } from './util/colors';
import { bytesToHexString, hexStringToUint8Array } from './util/convert';
import simpleCrypto from './util/simple-crypto';
import i18nError from './i18n-error';
import processResult from './process-result';
import relativeUrl from './util/relative-url';
import safe from './util/safe';
import validate from './util/validate';
import warn from './util/warn';

const MAX_PLUGINDATA_LENGTH = {
  board: 8192,
  card: 4096,
  member: 4096,
  organization: 8192,
};

const HostHandlers = {};

HostHandlers.getContext = function getContext() {
  return this.args[0].context;
};

HostHandlers.isMemberSignedIn = function isMemberSignedIn() {
  const context = this.getContext();
  return context != null && context.member !== 'notLoggedIn' && context.member != null;
};

HostHandlers.memberCanWriteToModel = function memberCanWriteToModel(modelType) {
  if (!this.isMemberSignedIn()) {
    return false;
  }

  const allowedModelTypes = ['board', 'card', 'organization'];
  if (typeof modelType !== 'string' || !includes(allowedModelTypes, modelType)) {
    throw new Error('modelType must be one of: board, card, organization');
  }

  const context = this.getContext();
  return context != null
    && context.permissions != null
    && context.permissions[modelType] === 'write';
};

HostHandlers.requestWithContext = function requestWithContext(command, options) {
  const opts = options || {};
  opts.context = typeof this.getContext === 'function' ? this.getContext() : {};

  return this.request(command, processResult(opts));
};

HostHandlers.getAll = function getAll() {
  const self = this;
  // enforce only a single data req at a time
  if (self.outstandingGetAllReq) {
    return self.outstandingGetAllReq;
  }

  self.outstandingGetAllReq = self.requestWithContext('data')
    .then((data) => {
      const parsed = {};

      Object.keys(data).forEach((scope) => {
        parsed[scope] = {};
        Object.keys(data[scope]).forEach((visibility) => {
          try {
            parsed[scope][visibility] = JSON.parse(data[scope][visibility]);
          } catch (ignored) {
            parsed[scope][visibility] = {};
          }
        });
      });

      self.outstandingGetAllReq = null;
      return parsed;
    })
    .catch((err) => {
      self.outstandingGetAllReq = null;
      throw err;
    });

  return self.outstandingGetAllReq;
};

HostHandlers.get = function get(scope, visibility, name, defaultValue) {
  const self = this;
  if (!self.outstandingGetRequests) {
    self.outstandingGetRequests = new Map();
  }

  let reqKey = 'GET';
  const opts = {};
  if (validate.isId(scope) || validate.isShortLink(scope)) {
    opts.idCard = scope;
    // eslint-disable-next-line no-param-reassign
    scope = 'card';
    reqKey = `GET:${opts.idCard}`;
  }

  if (!validate.isAllowedVisibilty(visibility)) {
    throw new Error('Invalid value for visibility.');
  }

  if (!validate.isAllowedScope(scope)) {
    throw new Error('Invalid value for scope.');
  }

  if (!self.outstandingGetRequests.has(reqKey)) {
    self.outstandingGetRequests.set(reqKey, self.requestWithContext('data', opts));
  }

  return self.outstandingGetRequests.get(reqKey)
    .then((data) => {
      let parsed = {};
      if (data && has(data, scope) && has(data[scope], visibility)) {
        try {
          parsed = JSON.parse(data[scope][visibility]);
        } catch (ignored) {
          // nothing to do
        }
      }
      self.outstandingGetRequests.delete(reqKey);
      // get all data at a certain scope & visibility
      if (name == null) {
        return parsed || defaultValue;
      }
      // get specific property at certain scope & visibility
      if (parsed != null && has(parsed, name)) {
        return parsed[name];
      }
      return defaultValue;
    })
    .catch((err) => {
      self.outstandingGetRequests.delete(reqKey);
      throw err;
    });
};

function internalSet(scope, visibility, options, fxDataTransformer) {
  const self = this;
  const opts = { ...options };
  // Make sure we wait for in-flight set requests to finish, before we
  // try to persist this one; otherwise we may clobber changes we've tried
  // to write just prior, due to the "outstandingGetRequests" logic in `self.get`
  if (!self.setRequestQueue) {
    self.setRequestQueue = new Map();
  }

  const reqKey = `PUT:${scope}:${visibility}`;
  const { chain, size } = self.setRequestQueue.has(reqKey)
    ? self.setRequestQueue.get(reqKey)
    : { chain: Promise.resolve(), size: 0 };

  const getFreshData = () => self.get(scope, visibility);
  const promise = chain
    .then(getFreshData, getFreshData)
    .then((data) => {
      const dataAtVis = data || {};
      const originalStringified = JSON.stringify(dataAtVis);
      const transformedData = fxDataTransformer(dataAtVis);

      opts.data = JSON.stringify(transformedData);

      if (opts.data === originalStringified) {
        // the requested change did not modify the current data
        return Promise.resolve();
      }

      if (opts.data.length > MAX_PLUGINDATA_LENGTH[opts.scope]) {
        throw new Error(`PluginData length of ${MAX_PLUGINDATA_LENGTH[opts.scope]} characters exceeded. See: https://developers.trello.com/v1.0/reference#section-size-limit`);
      }

      const req = self.requestWithContext('set', opts);
      req.finally(() => {
        const queue = self.setRequestQueue.get(reqKey);
        if (queue.size === 1) {
          self.setRequestQueue.delete(reqKey);
        } else {
          self.setRequestQueue.set({ chain: queue.chain, size: queue.size - 1 });
        }
      });
      return req;
    });

  self.setRequestQueue.set(reqKey, { chain: promise, size: size + 1 });

  return promise;
}

HostHandlers.set = function set(scope, visibility, name, value) {
  const opts = { scope, visibility };

  if (!validate.isAllowedVisibilty(visibility)) {
    throw new Error('Invalid value for visibility.');
  }

  if (validate.isId(scope) || validate.isShortLink(scope)) {
    opts.idCard = scope;
    opts.scope = 'card';
  }

  if (!validate.isAllowedScope(opts.scope)) {
    throw new Error('Invalid value for scope.');
  }

  return internalSet.call(this, scope, visibility, opts, (data) => {
    const result = { ...data };
    if (typeof name === 'object') {
      Object.keys(name).forEach((k) => {
        result[k] = name[k];
      });
    } else {
      result[name] = value;
    }
    return result;
  });
};

HostHandlers.remove = function remove(scope, visibility, names) {
  const opts = { scope, visibility };
  let keys = names;

  if (validate.isId(scope) || validate.isShortLink(scope)) {
    opts.idCard = scope;
    opts.scope = 'card';
  }

  if (!Array.isArray(keys)) {
    keys = [names];
  }

  if (keys.some((key) => typeof key !== 'string')) {
    warn('t.remove function takes either a single string or an array of strings for which keys to remove');
    return null;
  }

  if (!validate.isAllowedScope(opts.scope)) {
    throw new Error('Invalid value for scope.');
  }

  if (!validate.isAllowedVisibilty(visibility)) {
    throw new Error('Invalid value for visibility.');
  }

  return internalSet.call(this, scope, visibility, opts, (data) => {
    const result = { ...data };
    keys.forEach((key) => {
      delete result[key];
    });
    return result;
  });
};

HostHandlers.safe = safe;

HostHandlers.arg = function arg(name, defaultValue) {
  const options = this.args[1];
  if (options && typeof options === 'object' && has(options, name)) {
    return options[name];
  }
  return defaultValue;
};

HostHandlers.signUrl = function signUrl(url, args) {
  const context = this.getContext();
  const signature = encodeURIComponent(JSON.stringify({
    secret: this.secret,
    context,
    locale: window.locale,
    args,
  }));
  // check if its already been signed
  if (url.includes('#')) {
    warn('Power-Up signing url that already has a hash. This will remove the existing hash.', url);
    // strip the old hash and replace it with this one
    return `${url.slice(0, url.indexOf('#'))}#${signature}`;
  }
  return `${url}#${signature}`;
};

HostHandlers.navigate = function navigate(options) {
  if (!options || typeof options !== 'object' || typeof options.url !== 'string') {
    return Promise.reject(new Error('Invalid or missing url provided in options object'));
  }
  return this.requestWithContext('navigate', options);
};

HostHandlers.showCard = function showCard(idCard) {
  if (!idCard || typeof idCard !== 'string') {
    return Promise.reject(new Error('Invalid idCard provided'));
  }
  return this.requestWithContext('showCard', { idCard });
};

HostHandlers.hideCard = function hideCard() {
  return this.requestWithContext('hideCard');
};

HostHandlers.alert = function alert(options) {
  const maxAlertLength = 140;
  const opts = pick(options, ['message', 'duration', 'display']);
  const msg = opts.message;
  // message is required to be 1 - 140 chars
  if (!isString(msg) || msg.length < 1 || msg.length > maxAlertLength) {
    return Promise.reject(new Error('Alert requires a message of 1 to 140 characters'));
  }
  return this.requestWithContext('alert', opts);
};

HostHandlers.hideAlert = function hideAlert() {
  return this.requestWithContext('hideAlert');
};

const getContentForItemsPopup = (opts) => {
  let items;
  if (Array.isArray(opts.items) || typeof opts.items === 'function') {
    // eslint-disable-next-line prefer-destructuring
    items = opts.items;
  } else if (typeof opts.items === 'object') {
    items = Object.keys(opts.items).map((text) => {
      const entry = opts.items[text];
      if (typeof entry === 'function') {
        return {
          text,
          callback: entry,
        };
      }
      if (entry && typeof entry.callback === 'function') {
        return xtend({ text }, entry);
      }

      return { text };
    });
  } else {
    throw new Error('Unsupported items type for popup. Must be an array, object, or function');
  }

  return {
    items,
    type: 'list',
    search: opts.search,
  };
};

const getContentForConfirmPopup = (opts) => {
  if (typeof opts.message !== 'string' || typeof opts.confirmText !== 'string') {
    throw new Error('Confirm popups must have a message and confirmText');
  }
  if (typeof opts.onConfirm !== 'function') {
    throw new Error('Confirm popup requires onConfirm function');
  }
  if (typeof opts.onCancel === 'function' && typeof opts.cancelText !== 'string') {
    throw new Error('Confirm popup requires cancelText to support onCancel function');
  }
  const content = {
    type: 'confirm',
    message: opts.message,
    confirmStyle: opts.confirmStyle || 'primary',
    confirmText: opts.confirmText,
    onConfirm: opts.onConfirm,
  };
  if (typeof opts.cancelText === 'string') {
    content.cancelText = opts.cancelText;
  }
  if (typeof opts.onCancel === 'function') {
    content.onCancel = opts.onCancel;
  }
  return content;
};

const getContentForDatePopup = (opts) => {
  if (typeof opts.callback !== 'function') {
    throw new Error('Date popups must have a callback function');
  }

  const content = {
    type: opts.type,
    callback: opts.callback,
  };
  if (opts.date && typeof opts.date.toISOString === 'function') {
    content.date = opts.date.toISOString();
  }
  if (opts.minDate && typeof opts.minDate.toISOString === 'function') {
    content.minDate = opts.minDate.toISOString();
  }
  if (opts.maxDate && typeof opts.maxDate.toISOString === 'function') {
    content.maxDate = opts.maxDate.toISOString();
  }
  if (content.minDate && content.maxDate && content.minDate > content.maxDate) {
    throw new Error('Date popup maxDate must come after minDate if specified');
  }

  return content;
};

HostHandlers.popup = function popup(options) {
  if (!this.getContext().el && !(options && options.mouseEvent)) {
    warn('Unable to open popup. Context missing target element or a mouseEvent was not provided. This usually means you are using the wrong t param, and should instead use the one provided to the callback function itself, not the capability handler. If you are within an iframe, then make sure you pass the mouse event.');
    return Promise.reject(new Error('Context missing target element and no mouse event provided'));
  }
  const popupOptions = {
    title: options.title,
  };

  if (options && options.mouseEvent) {
    const { mouseEvent } = options;
    const { clientX, clientY } = mouseEvent;

    if (isFinite(clientX) && isFinite(clientY)) {
      let x = clientX;
      let y = clientY;

      // Assumes that they've triggered the click by using Tab + Enter.
      // Fall back to the target element of the click.
      if (x === 0 && y === 0) {
        if (!(mouseEvent.target && mouseEvent.target.getBoundingClientRect)) {
          return Promise.reject(new Error('Invalid mouseEvent was provided'));
        }

        const boundingRect = mouseEvent.target.getBoundingClientRect();
        x = boundingRect.left;
        y = boundingRect.top;
      }

      popupOptions.pos = { x, y };
    } else {
      return Promise.reject(new Error('Invalid mouseEvent was provided'));
    }
  }

  if (options && typeof options.callback === 'function') {
    popupOptions.callback = options.callback;
  }
  try {
    if (options.url && typeof options.url === 'string') {
      popupOptions.content = {
        type: 'iframe',
        url: this.signUrl(relativeUrl(options.url), options.args),
        width: options.width,
        height: options.height,
      };
    } else if (options.items) {
      popupOptions.content = getContentForItemsPopup(options);
    } else if (options.type === 'confirm') {
      popupOptions.content = getContentForConfirmPopup(options);
    } else if (options.type === 'datetime' || options.type === 'date') {
      delete popupOptions.callback;
      popupOptions.content = getContentForDatePopup(options);
    } else {
      return Promise.reject(new Error('Unknown popup type requested'));
    }
  } catch (err) {
    return Promise.reject(err);
  }
  return this.requestWithContext('popup', popupOptions);
};

HostHandlers.overlay = function overlay(options) {
  warn('overlay() has been deprecated. Please use modal() instead. See: https://trello.readme.io/v1.0/reference#t-modal');
  const overlayOptions = {};
  if (options.url) {
    overlayOptions.content = {
      type: 'iframe',
      url: this.signUrl(relativeUrl(options.url), options.args),
      inset: options.inset,
    };
  }
  return this.requestWithContext('overlay', overlayOptions);
};

HostHandlers.boardBar = function boardBar(options) {
  if (!options || !options.url || typeof options.url !== 'string') {
    throw new Error('BoardBar options requires a valid url');
  }
  if (options.actions && !Array.isArray(options.actions)) {
    throw new Error('BoardBar actions property must be an array');
  }
  let accentColor;
  if (options.accentColor) {
    accentColor = namedColorStringToHex(options.accentColor);
  }
  const boardBarOptions = {
    content: {
      actions: options.actions || [],
      callback: options.callback,
      accentColor,
      height: options.height || 200,
      resizable: options.resizable || false,
      title: options.title,
      type: 'iframe',
      url: this.signUrl(relativeUrl(options.url), options.args),
    },
  };
  return this.requestWithContext('board-bar', boardBarOptions);
};

HostHandlers.modal = function modal(options) {
  if (!options || !options.url || typeof options.url !== 'string') {
    throw new Error('Modal options requires a valid url');
  }
  if (options.actions && !Array.isArray(options.actions)) {
    throw new Error('Modal actions property must be an array');
  }
  let accentColor;
  if (options.accentColor) {
    accentColor = namedColorStringToHex(options.accentColor);
  }
  const modalOptions = {
    content: {
      actions: options.actions || [],
      callback: options.callback,
      accentColor,
      fullscreen: options.fullscreen || false,
      height: options.height || 400,
      title: options.title,
      type: 'iframe',
      url: this.signUrl(relativeUrl(options.url), options.args),
    },
  };
  return this.requestWithContext('modal', modalOptions);
};

HostHandlers.updateModal = function updateModal(options) {
  if (!options) {
    return Promise.resolve();
  }
  const {
    accentColor,
    actions,
    fullscreen,
    title,
  } = options;

  if (!accentColor && !actions && !fullscreen && !title) { // noop
    return Promise.resolve();
  }
  if (options.url) {
    throw new Error('Updating Modal url not allowed');
  }
  if (options.callback) {
    throw new Error('Unable to update callback. You can set onBeforeUnload to run code before Modal close: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload');
  }
  if (actions && !Array.isArray(actions)) {
    throw new Error('Modal actions property must be an array');
  }

  const modalOptions = {
    content: pick(options, ['actions', 'accentColor', 'fullscreen', 'title']),
  };
  if (accentColor) {
    modalOptions.content.accentColor = namedColorStringToHex(accentColor);
  }
  return this.requestWithContext('update-modal', modalOptions);
};

// Deprecated in favor of closePopup
HostHandlers.hide = function hide() {
  warn('hide() handler has been deprecated. Please use closePopup()');
  return this.requestWithContext('close-popup');
};

HostHandlers.closePopup = function closePopup() {
  return this.requestWithContext('close-popup');
};

HostHandlers.back = function back() {
  return this.requestWithContext('pop-popup');
};

// Deprecated in favor of closeOverlay
HostHandlers.hideOverlay = function hideOverlay() {
  warn('hideOverlay() handler has been deprecated. Please use closeOverlay()');
  return this.requestWithContext('close-overlay');
};

HostHandlers.closeOverlay = function closeOverlay(options = {}) {
  warn('overlay() has been deprecated. Please use modal() instead. See: https://trello.readme.io/v1.0/reference#t-modal');
  const closeOverlayOptions = {
    inset: options.inset,
  };
  return this.requestWithContext('close-overlay', closeOverlayOptions);
};

// Deprecated in favor of closeBoardBar
HostHandlers.hideBoardBar = function hideBoardBar() {
  warn('hideBoardBar() handler has been deprecated. Please use closeBoardBar()');
  return this.requestWithContext('close-board-bar');
};

HostHandlers.closeBoardBar = function closeBoardBar() {
  return this.requestWithContext('close-board-bar');
};

HostHandlers.closeModal = function closeModal() {
  return this.requestWithContext('close-modal');
};

/**
 * Asks Trello to alter the height of the iframe in context.
 * If arg is a string, it should be a querySelector that will select the element
 * that Trello will measure and request the height of.
 * If arg is an element, Trello will measure and request the height it has.
 * If arg is a positive number, that will be sent directly as the desired height.
 * @param {string|element|number} arg - How to determine requested size
 */
HostHandlers.sizeTo = function sizeTo(arg) {
  let requestedHeight;
  if (isString(arg)) {
    const el = document.querySelector(arg);
    if (el) {
      el.style.overflow = 'hidden';
      requestedHeight = Math.ceil(Math.max(el.scrollHeight, el.getBoundingClientRect().height));
    } else {
      return Promise.reject(new Error(`No elements matched sizeTo query selector: ${arg}`));
    }
  } else if (isElement(arg)) {
    const el = arg;
    el.style.overflow = 'hidden';
    requestedHeight = Math.ceil(Math.max(el.scrollHeight, el.getBoundingClientRect().height));
  } else if (isFinite(arg) && arg > 0) {
    requestedHeight = arg;
  } else {
    return Promise.reject(new Error(`Invalid argument. Must be a selector, element, or positive number. Was: ${arg}`));
  }

  if (requestedHeight) {
    return this.requestWithContext('resize', {
      height: requestedHeight,
    });
  }

  return Promise.reject(new Error(`Unable to determine desired height for ${arg} computed ${requestedHeight}`));
};

HostHandlers.localizeKey = (key, data) => {
  if (window.localizer && typeof window.localizer.localize === 'function') {
    return window.localizer.localize(key, data);
  }

  throw new i18nError.LocalizerNotFound('No localizer available for localization.');
};

HostHandlers.localizeKeys = function localizeKeys(keys) {
  if (!keys) {
    return [];
  }
  const self = this;
  return keys.map((key) => {
    if (typeof key === 'string') {
      return self.localizeKey(key);
    }
    if (Array.isArray(key)) {
      return self.localizeKey(key[0], key[1]);
    }

    throw new i18nError.UnsupportedKeyType(`localizeKeys doesn't recognize the supplied key type: ${typeof key}`);
  });
};

HostHandlers.localizeNode = function localizeNode(node) {
  const localizableNodes = node.querySelectorAll('[data-i18n-id],[data-i18n-attrs]');
  for (let i = 0, len = localizableNodes.length; i < len; i += 1) {
    let replacementArgs = {};
    const element = localizableNodes[i];
    if (element.dataset.i18nArgs) {
      try {
        replacementArgs = JSON.parse(element.dataset.i18nArgs);
      } catch (ex) {
        throw new i18nError.UnableToParseArgs(`Error parsing args. Error: ${ex.message}`);
      }
    }
    if (element.dataset.i18nId) {
      element.textContent = this.localizeKey(element.dataset.i18nId, replacementArgs);
    }
    if (element.dataset.i18nAttrs) {
      let requestedAttributes;
      try {
        requestedAttributes = JSON.parse(element.dataset.i18nAttrs);
      } catch (ex) {
        throw new i18nError.UnableToParseAttrs(`Error parsing attrs. Error: ${ex.message}`);
      }
      if (requestedAttributes && requestedAttributes.placeholder) {
        element.placeholder = this.localizeKey(requestedAttributes.placeholder, replacementArgs);
      }
    }
  }
};

HostHandlers.card = function card(...fields) {
  return this.requestWithContext('card', { fields });
};

HostHandlers.cards = function cards(...fields) {
  return this.requestWithContext('cards', { fields });
};

HostHandlers.list = function list(...fields) {
  return this.requestWithContext('list', { fields });
};

HostHandlers.lists = function lists(...fields) {
  return this.requestWithContext('lists', { fields });
};

HostHandlers.member = function member(...fields) {
  return this.requestWithContext('member', { fields });
};

HostHandlers.board = function board(...fields) {
  return this.requestWithContext('board', { fields });
};

HostHandlers.organization = function organization(...fields) {
  return this.requestWithContext('organization', { fields });
};

HostHandlers.attach = function attach(options) {
  if (!this.memberCanWriteToModel('card')) {
    throw new Error('User lacks write permission on card.');
  }

  return this.requestWithContext('attach-to-card', options);
};

HostHandlers.requestToken = function requestToken(options) {
  if (!this.isMemberSignedIn()) {
    throw new Error('No active member in context.');
  }

  return this.requestWithContext('request-token', options);
};

HostHandlers.authorize = (authUrl, options) => {
  let url;
  const secret = PostMessageIO.randomId();
  const opts = options || {};

  if (typeof authUrl === 'string') {
    url = authUrl;
  } else if (typeof authUrl === 'function') {
    url = authUrl(secret);
  } else {
    warn('authorize requires a url or function that takes a secret and returns a url');
    throw new Error('Invalid arguments passed to authorize');
  }

  let isValidToken = () => true;
  if (opts.validToken && typeof opts.validToken === 'function') {
    isValidToken = opts.validToken;
  }

  const width = opts.width || 800;
  const height = opts.height || 600;
  const left = window.screenX + Math.floor((window.outerWidth - width) / 2);
  const top = window.screenY + Math.floor((window.outerHeight - height) / 2);
  const windowOpts = ['width=', width, ',height=', height, ',left=', left, ',top=', top].join('');

  const storageEventHandler = (resolve) => {
    const handler = (e) => {
      if (e.key === 'token' && e.newValue && isValidToken(e.newValue)) {
        localStorage.removeItem('token');
        window.removeEventListener('storage', handler, false);
        delete window.authorize;
        resolve(e.newValue);
      }
    };
    return handler;
  };

  const openWindow = (targetUrl, newWindowOpts) => {
    const authWindow = window.open(targetUrl, 'authorize', newWindowOpts);
    if (typeof opts.windowCallback === 'function') {
      opts.windowCallback(authWindow);
    }
    return authWindow;
  };

  return new Promise((resolve) => {
    window.addEventListener('storage', storageEventHandler(resolve), false);
    if (typeof authUrl === 'function') {
      // eslint-disable-next-line no-new
      new PostMessageIO({
        Promise,
        local: window,
        remote: openWindow(url, windowOpts),
        targetOrigin: opts.targetOrigin || '*',
        secret,
        handlers: {
          value(t, o) {
            if (o && o.token && isValidToken(o.token)) {
              this.stop();
              resolve(o.token);
            }
          },
        },
      });
    } else {
      window.authorize = (token) => {
        if (token && isValidToken(token)) {
          delete window.authorize;
          resolve(token);
        }
      };
      openWindow(url, windowOpts);
    }
  });
};

HostHandlers.storeSecret = function storeSecret(secretKey, secretData) {
  if (!this.isMemberSignedIn()) {
    throw new Error('No active member in context.');
  }

  const idMember = this.getContext().member;
  const storageKey = `${idMember}:${secretKey}`;
  const self = this;

  // Generate a SHA-256 digest of secretKey, prefixed by idMember
  return simpleCrypto.sha256Digest(storageKey)
    // check to see if we already have an encryption key for this member
    .then((digestKey) => self.get('member', 'private', 'aescbc')
      .then((storedKey) => {
        if (storedKey) {
          // we already have a key stored, return it to carry on using that one
          return storedKey;
        }

        // if we don't already have a stored encryption key we need to create and store a new one
        return simpleCrypto.generateAESCBCKey()
          // in order to store the key in Trello, we need to export it
          .then((key) => simpleCrypto.exportAESCBCKeyToRaw(key));
      })
      // import the key so we can use it for encryption
      .then((storedKey) => simpleCrypto.importAESCBCKeyFromRaw(storedKey)
        .then((encryptionKey) => {
          // we need to generate a new random initialization vector to use for encryption
          const initVector = simpleCrypto.generateInitVector();
          return simpleCrypto.encryptSecret(initVector, encryptionKey, secretData)
            .then((encryptedData) => {
              const concatedData = `${bytesToHexString(initVector)};${encryptedData}`;
              window.localStorage.setItem(digestKey, concatedData);
              // wait until after we have successfully written the secret
              // in order to store the key in Trello since this may cause
              // re-calling of Power-Up capabilities that need it
              return self.set('member', 'private', 'aescbc', storedKey)
                .then(() => ({
                  key: digestKey,
                  value: concatedData,
                }));
            });
        })));
};

HostHandlers.loadSecret = function loadSecret(secretKey) {
  if (!this.isMemberSignedIn()) {
    throw new Error('No active member in context.');
  }

  const idMember = this.getContext().member;
  const self = this;
  const storageKey = `${idMember}:${secretKey}`;
  // Generate a SHA-256 digest of secretKey, prefixed by idMember
  return simpleCrypto.sha256Digest(storageKey)
    .then((secretKeyDigest) => window.localStorage.getItem(secretKeyDigest))
    .then((encryptedSecret) => {
      if (!encryptedSecret) {
        return null;
      }

      // before we can decrypt we need to fetch the encryption key from private plugin data
      return self.get('member', 'private', 'aescbc')
        .then((rawEncryptionKey) => {
          if (!rawEncryptionKey) {
            return null;
          }

          // now we need to import the key so it can be used for decryption
          return simpleCrypto.importAESCBCKeyFromRaw(rawEncryptionKey)
            .then((decryptionKey) => {
              // before we can use this key to decrypt we need to pull out the initialization vector
              const initVector = encryptedSecret.substring(0, encryptedSecret.indexOf(';'));
              const encryptedData = encryptedSecret.substring(encryptedSecret.indexOf(';') + 1);

              // currently the initVector is a hex string, let's convert that to an arrayBuffer
              const ivBuff = hexStringToUint8Array(initVector);
              return simpleCrypto.decryptSecret(ivBuff, decryptionKey, encryptedData);
            });
        });
    });
};

HostHandlers.clearSecret = function clearSecret(secretKey) {
  if (!this.isMemberSignedIn()) {
    throw new Error('No active member in context.');
  }

  const idMember = this.getContext().member;
  const storageKey = `${idMember}:${secretKey}`;
  // Generate a SHA-256 digest of secretKey, prefixed by idMember
  return simpleCrypto.sha256Digest(storageKey)
    .then((secretKeyDigest) => {
      window.localStorage.removeItem(secretKeyDigest);
      return null;
    });
};

HostHandlers.notifyParent = (message, options) => {
  const opts = options || {};
  window.parent.postMessage(message, opts.targetOrigin || '*');
};

HostHandlers.confetti = function confetti(arg) {
  const confettiOptions = {};

  if (
    arg
    && isFinite(arg.clientX)
    && isFinite(arg.clientY)
    && isElement(arg.target)
  ) {
    const mouseEvent = arg;
    const { clientX, clientY } = mouseEvent;

    let x = clientX;
    let y = clientY;

    // Assumes that they've triggered the click by using Tab + Enter.
    // Fall back to the target element of the click.
    if (x === 0 && y === 0) {
      if (!(mouseEvent.target && mouseEvent.target.getBoundingClientRect)) {
        return Promise.reject(new Error('Invalid mouseEvent was provided'));
      }

      const boundingRect = mouseEvent.target.getBoundingClientRect();
      x = boundingRect.left + mouseEvent.target.offsetWidth / 2;
      y = boundingRect.top + mouseEvent.target.offsetHeight / 2;
    }

    confettiOptions.pos = { x, y };
  } else {
    let el;

    if (isElement(arg)) {
      el = arg;
    } else if (isString(arg)) {
      el = document.querySelector(arg);
      if (!el) {
        return Promise.reject(
          new Error(`No elements matched confetti query selector: ${arg}`),
        );
      }
    }

    if (el) {
      const boundingRect = el.getBoundingClientRect();
      confettiOptions.pos = {
        x: boundingRect.left + el.offsetWidth / 2,
        y: boundingRect.top + el.offsetHeight / 2,
      };
    }
  }

  return this.requestWithContext('confetti', confettiOptions);
};

const jwtRequests = new Map();
HostHandlers.jwt = function jwt(options) {
  if (!this.isMemberSignedIn()) {
    return Promise.reject(new Error('No active member in context.'));
  }

  // if you ask to include the card in the JWT it must be in context
  const includeCard = options?.card === true;
  if (includeCard && !this.getContext().card) {
    return Promise.reject(new Error('No card in context'));
  }

  let state = '';
  if (typeof options?.state === 'string') {
    if (options.state.length > 2048) {
      return Promise.reject(new Error('State parameter must be a string of at most 2048 characters'));
    }
    state = options.state;
  } else if (options?.state != null) {
    // they gave a state that is not a string, let them know that is wrong
    return Promise.reject(new Error('State parameter must be a string of at most 2048 characters'));
  }

  const jwtKey = [
    this.getContext().board,
    includeCard ? this.getContext().card : '',
    state,
  ].join(':');

  // try to limit to a single request per state at a time
  // the web client will manage the caching of actual JWTs for us by expiry
  if (jwtRequests.has(jwtKey)) {
    return jwtRequests.get(jwtKey);
  }

  const request = this.requestWithContext('jwt', { state, includeCard });
  request.finally(() => {
    // when the request completes remove it from the map
    jwtRequests.delete(jwtKey);
  });

  jwtRequests.set(jwtKey, request);
  return jwtRequests.get(jwtKey);
};

export default HostHandlers;
