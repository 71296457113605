import Promise from 'bluebird';

import restApiError from './error';

const AuthMethods = {};

AuthMethods.makeWebCall = function makeWebCall(method, args = []) {
  if (!this.t) {
    throw new restApiError.AuthNotReadyError('The API helper cannot be used from this context. This probably means that you are attemping to use it inside your iframe connector, but outside a capability handler. For more, http://developers.trello.com/v1.0/reference#api-client-availability.');
  }

  if (typeof this.t[method] !== 'function') {
    throw new restApiError.AuthNotReadyError('This method cannot be used in this context. This probably means that you are attemping to use it inside your iframe connector, but outside a capability handler. For more, http://developers.trello.com/v1.0/reference#api-client-availability.');
  }

  return this.t[method](...args);
};

const validToken = (token) => /^[a-z0-9]{64}$/.test(token);

AuthMethods.registerMessageHandler = function registerMessageHandler(popup) {
  return new Promise((resolve, reject) => {
    let interval;
    let removeHandlers;

    const checkPopup = () => {
      if (popup && popup.closed) {
        clearInterval(interval);
        reject(new restApiError.AuthDeniedError());
      }
    };

    interval = setInterval(checkPopup, 500);

    const messageHandler = (e) => {
      if (e.origin !== window.location.origin || e.source !== popup) {
        return;
      }

      removeHandlers();
      e.source.close();

      if (!e.data || !validToken(e.data)) {
        reject(new restApiError.AuthDeniedError());
        return;
      }

      resolve(e.data);
    };

    const storageHandler = (e) => {
      if (e.key === this.tokenStorageKey) {
        removeHandlers();
        this.fetchAndStoreToken()
          .then((token) => {
            if (token) {
              resolve(token);
            } else {
              reject(new restApiError.AuthDeniedError());
            }
          });
      }
    };

    removeHandlers = () => {
      window.removeEventListener('storage', storageHandler, false);
      window.removeEventListener('message', messageHandler, false);
      clearInterval(interval);
    };

    window.addEventListener('message', messageHandler, false);
    window.addEventListener('storage', storageHandler, false);
  });
};

AuthMethods.storeToken = function storeToken(token) {
  return this.makeWebCall('set', ['member', 'private', this.tokenStorageKey, token])
    .then(() => token);
};

AuthMethods.checkForToken = function checkForToken() {
  // the signed-out flow puts the token in localStorage when it's done. If it's
  // there, grab it and persist it.
  let token;
  try {
    token = this.localStorage.getItem(this.tokenStorageKey);
  } catch (err) {
    // if we can't get the token from localStorage, there isn't much we can do.
    // If the user re-attempts auth it should work, because they're logged in
    // now, and the logged-in flow does not use localstorage
  }

  if (validToken(token)) {
    return token;
  }

  return null;
};

AuthMethods.fetchAndStoreToken = function fetchAndStoreToken() {
  const token = this.checkForToken();

  if (token) {
    return this.storeToken(token)
      .then(() => {
        this.localStorage.removeItem(this.tokenStorageKey);
        return token;
      });
  }

  return Promise.resolve(null);
};

AuthMethods.getToken = function getToken() {
  return this.fetchAndStoreToken()
    .then((token) => {
      if (token) {
        return token;
      }

      return this.makeWebCall('get', ['member', 'private', this.tokenStorageKey]);
    });
};

AuthMethods.clearToken = function clearToken() {
  try {
    this.localStorage.removeItem(this.tokenStorageKey);
  } catch (err) {
    // nothing to do
  }
  return this.makeWebCall('remove', ['member', 'private', this.tokenStorageKey]);
};

AuthMethods.popupConfig = function popupConfig() {
  const popupWidth = 550;
  const popupHeight = 725;
  const popupLeft = window.screenX + ((window.outerWidth - popupWidth) / 2);
  const popupTop = window.screenY + ((window.outerHeight - popupHeight) / 2);
  return `width=${popupWidth},height=${popupHeight},left=${popupLeft},top=${popupTop}`;
};

AuthMethods.showAuthPopup = function showAuthPopup({ expiration = 'never', scope = 'read', returnUrl = null }) {
  const authParams = {
    name: this.appName,
    key: this.appKey,
    expiration,
    scope,
  };

  authParams.callback_method = 'fragment';
  authParams.response_type = 'fragment';
  authParams.return_url = returnUrl || (window.location.href);

  let url = `${this.authBase()}/authorize?`;
  url += Object.keys(authParams).map((k) => `${k}=${encodeURIComponent(authParams[k])}`).join('&');

  return window.open(url, 'authpopup', this.popupConfig());
};

AuthMethods.authorize = function authorize(authOpts = {}) {
  return new Promise((resolve, reject) => {
    // it would be better to only show the popup after confirming the token's
    // not in storage, but fetching the token is async, and our popup would get
    // blocked in the .then, so we just have to do it now and close it later if
    // we already authed.
    const popup = this.showAuthPopup(authOpts);

    // check if there's an existing token and close the popup if so
    this.getToken()
      .then((token) => {
        if (token) {
          popup.close();
          resolve(token);
        }
      });

    this.registerMessageHandler(popup)
      .then(resolve)
      .catch(reject);
  })
    .then((token) => this.storeToken(token));
};

AuthMethods.isAuthorized = function isAuthorized() {
  return this.getToken()
    .then((token) => validToken(token));
};

AuthMethods.checkAndStoreToken = function checkAndStoreToken() {
  // Two things might be happening here, but we're combining them to make
  // the API simpler for developers.
  //
  // 1. Trello has redirected to us with a token in the hash, in which case
  // we should forward it to our iframe and then close the window
  //
  // 2. we're inside an iframe inside trello, in which case we should look
  // to see if there's a token waiting in localstorage. If there is, put it
  // into plugindata

  // Trello redirects to our domain with #token= (i.e., token=empty string) when they
  // click deny. We want to still send that on, so that the iframe can respond
  const matches = window.location.hash.match(/token=(.+)?/);
  if (matches && matches.length === 2) {
    const token = validToken(matches[1]) ? matches[1] : '';
    try {
      // We need localStorage because there are two cases
      // where `window.opener` is null. They are:
      //
      // 1. When the user was initially logged out, then logged in and
      // authorized the pup. In this case, the Trello web client refreshes
      // itself, so we lose `window.opener`; and
      // 2. In the Trello desktop app. Due to Electron weirdness, window.opener
      // is not set
      this.localStorage.setItem(this.tokenStorageKey, token);
    } catch (err) {
      // we will try to postMessage. if window.opener is not set now, hopefully
      // the user tries again, and it'll be set next time (as in case 1 above.)
    }

    try {
      // We need postMessage because localStorage does not work across windows
      // in Safari, and because localStorage in general can throw in various
      // scenarios.
      if (window.opener) {
        window.opener.postMessage(token, window.location.origin);
      }
    } catch (err) {
      // whelp we tried
    }

    // wait a bit to give the postMessage/localStorage handler a head-start
    window.setTimeout(() => window.close(), 500);
  } else {
    this.fetchAndStoreToken();
  }
};

export default AuthMethods;
