import has from 'lodash.has';
import PostMessageIO from '@atlassian/trello-post-message-io';
import Promise from 'bluebird';
import xtend from 'xtend';

import CallbackCache from './callback-cache';
import HostHandlers from './host-handlers';
import initi18n from './initialize-i18n';
import initIO from './initialize-io';
import processResult from './process-result';
import warn from './util/warn';

import RestApi, { restApiError } from './rest-api';

class TrelloPlugin {
  constructor(handlers, options = {}) {
    this.handlers = {};
    this.io = null;
    this.NotHandled = PostMessageIO.NotHandled;
    this.options = options;

    if (options.Sentry) {
      options.Sentry.configureScope((scope) => {
        scope.setTag('powerupjs_version', 'BUILD_VERSION');
      });
    }

    const self = this;
    Object.keys(handlers).forEach((command) => {
      self.handlers[command] = function handleCommand(...args) {
        const innerSelf = this;
        return Promise.try(() => handlers[command].apply(innerSelf, args))
          .then(processResult);
      };
    });

    this.handlers.callback = function callback(t, cbOpts) {
      return CallbackCache.callback.call(this, t, cbOpts, processResult);
    };

    const anonymousHandlers = [
      'requestWithContext',
      'getAll',
      'get',
      'set',
      'remove',
      'safe',
      'localizeKey',
      'localizeKeys',
      'localizeNode',
      'board',
      'cards',
      'lists',
      'member',
      'organization',
    ];

    anonymousHandlers.forEach((method) => {
      if (has(HostHandlers, method)) {
        self[method] = HostHandlers[method];
      }
    });
  }

  connect() {
    const self = this;
    const io = initIO(this.handlers, xtend(this.options, {
      hostHandlers: xtend(HostHandlers, {
        getRestApi() {
          if (!self.restApi) {
            throw new restApiError.ApiNotConfiguredError('To use the API helper, make sure you specify appKey and appName when you call TrelloPowerup.initialize. For more, https://developers.trello.com/v1.0/reference#rest-api.');
          }

          self.restApi.t = this;
          return self.restApi;
        },
      }),
    }));
    this.io = io;

    return io.request('initialize', Object.keys(this.handlers))
      .then((init) => {
        io.secret = init.secret;
        window.locale = init.locale || 'en';

        if (this.options.Sentry && typeof init === 'object') {
          // configure static variables that we know won't change this session
          this.options.Sentry.configureScope((scope) => {
            scope.setTag('locale', window.locale);
            scope.setTag('trello_version', init.version || 'unknown');
            if (init.member) {
              scope.setUser({ id: init.member });
            }
          });
        }

        return initi18n(window.locale, this.options)
          .then(() => io.request('ready'));
      })
      .then(() => io);
  }

  request(command, options) {
    return this.io.request(command, options);
  }

  init() {
    if (this.options.appKey && this.options.appName) {
      this.restApi = new RestApi({
        t: this,
        appKey: this.options.appKey,
        appName: this.options.appName,
        apiOrigin: this.options.apiOrigin,
        authOrigin: this.options.authOrigin,
        localStorage: this.options.localStorage,
        tokenStorageKey: this.options.tokenStorageKey,
      });

      return this.connect()
        .tap(() => this.restApi.init());
    }

    if (this.options.appKey || this.options.appName) {
      // if we got here bc they forgot to specify one of the options, try to help them out
      warn('Both appKey and appName must be included to use the API. See more https://developers.trello.com/v1.0/reference#rest-api.');
    }

    return this.connect();
  }
}

TrelloPlugin.prototype.NotHandled = PostMessageIO.NotHandled;

export default TrelloPlugin;
