import has from 'lodash.has';
import PostMessageIO from '@atlassian/trello-post-message-io';
import xtend from 'xtend';

import arg from './util/arg';
import CallbackCache from './callback-cache';
import HostHandlers from './host-handlers';
import initi18n from './initialize-i18n';
import initIO from './initialize-io';
import processResult from './process-result';
import RestApi, { restApiError } from './rest-api';
import warn from './util/warn';

class TrelloIFrame {
  constructor(options = {}) {
    this.io = null;
    this.args = [{
      context: arg('context', options.context),
      secret: arg('secret', options.secret),
    }].concat(arg('args'));
    this.secret = arg('secret', options.secret);
    this.options = options;
    window.locale = arg('locale', 'en');

    // we will start getting your localization ready immediately
    // but it won't be guaranteed ready to go until we call your render function
    this.i18nPromise = initi18n(window.locale, options);

    // since this is for a secondary iframe, if we don't have a secret something is probably wrong
    // Trello won't respond to our requests that don't include a secret
    if (!this.secret) {
      warn('Power-Up iframe initialized without a secret. Requests to Trello will not work.');
      warn('If this is an attachment-section or card-back-section make sure you call t.signUrl on the urls you provide.');
    }
  }

  init() {
    this.initSentry();
    this.connect();
    this.initApi();
  }

  connect() {
    const handlers = {
      callback(t, options) {
        return CallbackCache.callback.call(this, t, options, processResult);
      },
    };
    this.io = initIO(handlers, xtend(this.options, {
      secret: arg('secret'),
      hostHandlers: HostHandlers,
    }));
  }

  request(command, options) {
    return this.io.request(command, options);
  }

  render(fxRender) {
    if (typeof fxRender !== 'function') {
      throw new TypeError('Argument passed to render must be a function');
    }
    const self = this;
    // cleanup old listeners in case this is called multiple times
    if (self.onMessage) {
      window.removeEventListener('message', self.onMessage, false);
    }
    self.onMessage = (e) => {
      if (e.source === window.parent && e.data === 'render') {
        self.i18nPromise.then(() => { fxRender(); });
      }
    };
    window.addEventListener('message', self.onMessage, false);
  }

  initApi() {
    if (!this.options.appKey || !this.options.appName) {
      // if we got here bc they forgot to specify one of the options, try to help them out
      if (this.options.appKey || this.options.appName) {
        warn('Both appKey and appName must be included to use the API. See more at https://developers.trello.com/v1.0/reference#rest-api.');
      }

      return;
    }

    this.restApi = new RestApi({
      t: this,
      appKey: this.options.appKey,
      appName: this.options.appName,
      apiOrigin: this.options.apiOrigin,
      authOrigin: this.options.authOrigin,
      localStorage: this.options.localStorage,
      tokenStorageKey: this.options.tokenStorageKey,
    });
    this.restApi.init();
  }

  getRestApi() {
    if (!this.restApi) {
      throw new restApiError.ApiNotConfiguredError('To use the API helper, make sure you specify appKey and appName when you call TrelloPowerUp.iframe. See more at https://developers.trello.com/v1.0/reference#rest-api');
    }

    return this.restApi;
  }

  /**
   * If developers provide their Sentry object, we will be nice and help to set
   * as much context for them as possible. See:
   * https://docs.sentry.io/enriching-error-data/context/?platform=browser
   * https://docs.sentry.io/enriching-error-data/scopes/?platform=browser
   */
  initSentry() {
    if (this.options.Sentry) {
      // in the case of a secondary iframe, the context is signed into the URL
      // this means that we don't have to worry about it changing
      const context = arg('context', this.options.context);
      this.options.Sentry.configureScope((scope) => {
        scope.setTag('locale', arg('locale', 'en'));
        scope.setTag('powerupjs_version', 'BUILD_VERSION');
        if (!context || typeof context !== 'object') {
          return;
        }
        scope.setTag('trello_version', context.version || 'unknown');
        if (context.member) {
          scope.setUser({ id: context.member });
        }
        if (context.board) {
          scope.setTag('idBoard', context.board);
        }
        if (context.permissions) {
          Object.keys(context.permissions).forEach((perm) => {
            scope.setExtra(`${perm}_permission`, context.permissions[perm]);
          });
        }
      });
    }
  }
}

TrelloIFrame.prototype.NotHandled = PostMessageIO.NotHandled;

// eslint-disable-next-line no-restricted-syntax
for (const method in HostHandlers) {
  if (has(HostHandlers, method)) {
    TrelloIFrame.prototype[method] = HostHandlers[method];
  }
}

export default TrelloIFrame;
